
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ProjectModel } from '@/components/project/project-model'
import logging from '@/utils/logging'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class ProjectsSelect extends Vue {
  @Prop({ default: '' })
  modelValue!: string

  @Prop({ default: {} })
  project!: ProjectModel

  @Prop({ default: false })
  disable!: boolean

  @Prop({ default: [true] })
  rules!: boolean[]

  @Prop({ default: [false] })
  hasAll!: boolean[]

  @Prop({ default: false })
  ownerOnly!: boolean

  get value() {
    return this.modelValue
  }

  set value(value: string) {
    this.$emit('update:modelValue', value)
  }

  get workspaceId() {
    return this.$route.params.workspaceId || this.$store.getters.project?.workspaceId
  }

  get userInfo() {
    return this.$store.getters.userInfo
  }

  get projects(): ProjectModel[] {
    let projects: ProjectModel[] = []
    if (this.ownerOnly) {
      projects = this.$store.getters.ownProjects
    } else {
      projects = this.$store.getters.projects(this.workspaceId)
    }

    if (this.hasAll) {
      const all = new ProjectModel({
        _id: '',
        title: 'All',
      })

      projects = [all].concat(projects)
    }

    return projects
  }

  beforeMount() {
    logging.debugRender(ProjectsSelect.name)
  }

  created() {
    if (!this.modelValue && this.projects.length) {
      this.value = this.projects[0]._id || ''
    }
  }
}
