
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IGuestUrlSetting } from './guest-url-model'

@Options({})
export default class GuestUrlSettings extends Vue {
  @Prop()
  modelValue!: IGuestUrlSetting

  get value(): IGuestUrlSetting {
    return this.modelValue || {}
  }

  set value(value: IGuestUrlSetting) {
    this.$emit('update:modelValue', value)
  }
}
