<template>
  <q-dialog v-model="visible">
    <q-card style="width: 500px">
      <q-toolbar>
        <q-toolbar-title>
          {{ modalTitle }}
        </q-toolbar-title>

        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-card-section>
        <q-form ref="formRef">
          <div class="q-gutter-sm">
            <ProjectsSelect
              :hasAll="false"
              :ownerOnly="true"
              :disable="!isNew"
              v-model="form.projectId"
              :rules="[val => (val && val.length > 0) || requiredRule.message]"
            />
          </div>

          <label class="text-grey-9 text-bold">Guest Name</label>
          <q-input
            lazy-rules
            :rules="[val => (val && val.length > 0) || requiredRule.message]"
            outlined
            v-model="form.displayName"
            dense
            placeholder="guest name"
          />
          <GuestUrlSettings v-model="form.settings" />
          <q-space class="q-my-md" />
          <q-toggle dense v-model="form.active" label="Active?" />
          <div class="q-gutter-sm text-right">
            <q-btn color="white" text-color="black" @click="onCancel">Cancel</q-btn>
            <q-btn color="primary" @click="onSubmit"> Save </q-btn>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'

import { QForm } from 'quasar'
import logging from '@/utils/logging'
import { GuestUrlModel, IGuestUrl } from '@/components/guest-url/guest-url-model'
import GuestUrlMixin from './mixins/GuestUrlMixin.vue'
import ProjectsSelect from '../project/ui/ProjectsSelect.vue'
import GuestUrlSettings from './GuestUrlSetting.vue'

@Options({
  components: { ProjectsSelect, GuestUrlSettings },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class GuestUrlFormModal extends mixins(GuestUrlMixin) {
  @Prop()
  dataGuestUrl!: GuestUrlModel

  @Prop()
  modalVisible!: boolean

  loading = false
  requiredRule = {
    message: 'This field is required',
  }

  form: IGuestUrl = {
    displayName: '',
    projectId: '',
    active: true,
    settings: {},
  }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get modalTitle() {
    return this.isNew ? 'Add new guest url' : 'Edit guest information'
  }

  get isNew() {
    return !this.dataGuestUrl?._id
  }

  onSubmit() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
        this.onCancel()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async doSave() {
    if (this.isNew) {
      return this.addGuestUrl(this.form)
    }
    return this.updateGuestUrl(this.form)
  }

  onCancel() {
    this.visible = false
  }

  async created() {
    if (this.dataGuestUrl?._id) {
      this.form = { ...this.form, ...this.dataGuestUrl.serialize() }
    }
  }
}
</script>
