
import { Vue, Options } from 'vue-class-component'
import { IGuestUrl } from '@/components/guest-url/guest-url-model'
import { ACTION_GUEST_URL } from '../guest-url-actions'

@Options({
  components: {},
})
export default class GuestUrlMixin extends Vue {
  get workspaceId() {
    return this.$route.params.workspaceId
  }

  async getGuestUrls() {
    return this.$store.dispatch(ACTION_GUEST_URL.LOAD_ITEMS, { workspaceId: this.workspaceId })
  }

  async addGuestUrl(record: IGuestUrl) {
    return this.$store.dispatch(ACTION_GUEST_URL.ADD, { guestUrl: record })
  }

  async updateGuestUrl(record: IGuestUrl) {
    return this.$store.dispatch(ACTION_GUEST_URL.UPDATE, record)
  }

  async refreshGuestUrl(record: IGuestUrl) {
    return this.$store.dispatch(ACTION_GUEST_URL.REFRESH_TOKEN, record)
  }

  async removeGuestUrl(record: IGuestUrl) {
    return this.$store.dispatch(ACTION_GUEST_URL.DELETE, { id: record._id })
  }
}
