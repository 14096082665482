<template>
  <div class="row q-col-gutter-x-md q-gutter-y-md">
    <div class="col-12">
      <label class="text-grey-9 text-bold">Settings</label>
      <q-input dense outlined clearable v-model="value.expiredDate" mask="date" label="Expired Date">
        <template v-slot:prepend>
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
              <q-date minimal dense v-model="value.expiredDate">
                <div class="row items-center justify-end">
                  <q-btn v-close-popup label="Close" color="primary" flat />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </div>
    <div class="col-12">
      <q-input
        lazy-rules
        outlined
        dense
        clearable
        maxlength="3"
        type="number"
        label="Number of tickets per day"
        class="hide-spin-button"
        v-model.number="value.ticketPerDay"
      />
    </div>
    <div class="col-12">
      <q-input
        lazy-rules
        outlined
        dense
        clearable
        maxlength="3"
        type="number"
        label="Number of tickets per week"
        class="hide-spin-button"
        v-model.number="value.ticketPerWeek"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IGuestUrlSetting } from './guest-url-model'

@Options({})
export default class GuestUrlSettings extends Vue {
  @Prop()
  modelValue!: IGuestUrlSetting

  get value(): IGuestUrlSetting {
    return this.modelValue || {}
  }

  set value(value: IGuestUrlSetting) {
    this.$emit('update:modelValue', value)
  }
}
</script>
