
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { formatDateTime } from '@/utils/helpers'

import cloneDeep from 'lodash/cloneDeep'
import GuestUrlFormModal from '@/components/guest-url/GuestUrlFormModal.vue'
import GuestUrlMixin from '@/components/guest-url/mixins/GuestUrlMixin.vue'
import ProjectsSelect from '@/components/project/ui/ProjectsSelect.vue'
import { GuestUrlModel, IGuestUrl } from '@/components/guest-url/guest-url-model'
import { UserModel } from '@/components/user/user-model'

@Options({
  components: {
    GuestUrlFormModal,
    ProjectsSelect,
  },
  directives: { maska },
})
export default class GuestURLList extends mixins(GuestUrlMixin) {
  modalVisible = false
  selectedGuestURL: GuestUrlModel | null = null

  selectedProjectId = ''
  searchQuery = ''

  columns = [
    {
      label: 'Active?',
      name: 'active',
      field: 'active',
      style: 'width: 40px',
    },
    {
      label: 'Project',
      name: 'project',
      field: 'project',
      sortable: true,
      sort: (a: IGuestUrl, b: IGuestUrl) => {
        return (a.projectId || '') >= (b.projectId || '')
      },
    },
    {
      label: 'Guest Name',
      name: 'displayName',
      field: 'displayName',
      sortable: true,
      sort: (a: IGuestUrl, b: IGuestUrl) => {
        return (a.displayName || '') >= (b.displayName || '')
      },
    },
    {
      label: 'URL',
      name: 'url',
      field: 'token',
    },
    {
      label: 'Updated',
      name: 'updatedAt',
      field: 'updatedAt',
      format: (val: string) => formatDateTime(val),
    },
    {
      label: '#',
      name: 'action',
      style: 'width: 100px',
    },
  ]

  get guestUrls(): GuestUrlModel[] {
    return this.$store.getters.guestUrls(this.workspaceId) || []
  }

  get userInfo(): UserModel {
    return this.$store.getters.userInfo || {}
  }

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get guestUrlsFiltered() {
    const guestUrls = cloneDeep(this.guestUrls)
    return guestUrls.filter((item: GuestUrlModel) => {
      const toSearch = `${item.displayName}${item.project?.title}`.toLowerCase()
      const keyword = this.searchQuery.toLowerCase()
      const match1 = !this.searchQuery || toSearch.indexOf(keyword) >= 0
      const match2 = !this.selectedProjectId || item.projectId === this.selectedProjectId

      return match1 && match2
    })
  }

  makeLinkGuestUrl(record: GuestUrlModel) {
    const path = this.$router.resolve({
      name: 'guest_page',
      params: { guestToken: record.token || '' },
    })?.fullPath

    return location.origin + path
  }

  toggleClick(record: GuestUrlModel) {
    // [GuestUrlMixin]
    this.updateGuestUrl({ ...record.serialize() })
  }

  onRefreshToken(record: GuestUrlModel) {
    // [GuestUrlMixin]
    this.refreshGuestUrl({ ...record.serialize() })
  }

  onAdd() {
    this.selectedGuestURL = null
    this.modalVisible = true
  }

  onEdit(data: GuestUrlModel) {
    this.selectedGuestURL = data
    this.modalVisible = true
  }

  onCloseModal() {
    this.modalVisible = false
    this.selectedGuestURL = null
  }

  async onDelete(record: IGuestUrl) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(() => {
        this.removeGuestUrl(record)
      })
  }

  created() {
    this.$meta.setMeta({ title: ['Guest urls setting'] })
    this.getGuestUrls()
  }
}
